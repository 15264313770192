<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p
            class="large--title m-0"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            {{ $t("message.explanatoryLetter") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <div
            v-if="managers"
            class="pb-2"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            <b>Ma'sul Xodimlar: </b>
            <span v-for="manager in managers" :key="manager.id"
              >{{ manager.name }} {{ manager.last_name }},
            </span>
          </div>
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="staff_id">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.staff") }}</span
                  >
                  <select-staff
                    :id="form.staff_id"
                    v-model="form.staff_id"
                  ></select-staff>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-0 line-h-24"
                    >{{ $t("message.comment") }}</span
                  >
                  <el-input
                    :class="mode ? 'input__day' : 'input__night'"
                    :size="'medium'"
                    :placeholder="$t('message.comment')"
                    :inputValue="form.comment"
                    :type="'textarea'"
                    v-model="form.comment"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <div id="app" class="my-upload">
                  <span
                    :class="mode ? 'text__day2' : 'text__night2'"
                    class="input--label d-block mb-3"
                  >
                    {{ $t("message.img") }}
                  </span>

                  <el-upload
                    class="upload-demo"
                    action="/"
                    :limit="1"
                    :on-change="createImageList"
                    :file-list="imageList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :class="mode ? '' : 'upload__mynight'"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import selectStaff from "@/components/filters/inventory/select-staff";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
  mixins: [drawer, form, show],
  name: "PenaltyController",
  components: { selectStaff },
  data() {
    return {
      managers: null,
      penalty_reason: null,
      imageList: [],
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
      image: ""
    };
  },
  computed: {
    ...mapGetters({
      rules: "explanatoryLetter/rules",
      model: "explanatoryLetter/model",
      columns: "explanatoryLetter/columns",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      removeFile: "explanatoryLetter/removeFile",
      save: "explanatoryLetter/update",
      show: "explanatoryLetter/show"
    }),
    fetchData() {
      console.log("sadalksdjm");
      if (!this.loadingData) {
        this.loadingData = true;
        this.show(this.selectedItem.id)
          .then(res => {
            this.form = JSON.parse(
              JSON.stringify(res.data.result.data.explanatory_letter)
            );
            console.log(this.form.image);
            if (this.form) {
              this.imageList.push({
                name: this.form.image,
                url: this.baseUrl + this.form.image
              });
            }
            this.loadingData = false;
          })
          .catch(err => {
            this.loadingData = false;
          });
      }
    },
    afterOpen() {
      this.fetchData();
    },
    createImageList(file) {
      this.form.image = file.raw;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    submit(close = true) {
      this.loadingButton = true;
      this.$refs["form"].validate(valid => {
        if (valid) {
          let formData = new FormData();
          for (const key in this.form) {
            formData.append(key, this.form[key]);
          }
          formData["id"] = this.form.id;

          this.save(formData)
            .then(res => {
              this.loadingButton = false;
              this.fileList = [];
              this.imageList = [];
              this.loadingButton = false;
              this.parent().listChanged();
              console.log(res);
              if (close) this.close();
              if (res.status == 201) {
                this.$alert(res);
              }
            })
            .catch(err => {
              console.log(err);
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    setName(name) {
      var data = {
        id: 1,
        penalty_id: 2
      };
      this.removeFile(data).then(res => {
        console.log(res);
      });
      // console.log(name);
    }
  }
};
</script>

<style lang="scss">
.active {
  .el-upload-list__item-status-label {
    display: inline-block !important;
  }
}
.el-upload-list__item:hover {
  .el-upload-list__item-status-label {
    display: none !important;
  }
}
.my-upload {
  min-height: 200px !important;
}
.my-upload .el-upload-list {
  position: relative;
  z-index: 2;
}
.my-upload .el-upload.el-upload--picture-card {
  position: absolute;
  left: 0px !important;
  z-index: 1;
  width: 146px;
  line-height: 150px;
  vertical-align: top;
}
.my-upload .upload-demo {
  position: relative;
}

.upload__mynight {
  .el-upload.el-upload--picture-card {
    background-color: transparent;
  }
}
</style>
