<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="16">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.explanatoryLetter") }}
              </div>
              <div class="block-sarche">
                <div class="header__search d-flex mobile__column">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    class="space__input"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  <div class="mx-3 space__input">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                  </div>
                  <div class="mx-3 space__input">
                    <el-date-picker
                      v-model="filterForm.from_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.from')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <div class="mx-3 mr0__mobile space__input">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.before')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :can_create="true"
              :v_can_create="'explanatory_letters.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>
              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>
              <th v-if="columns.is_show.show">
                {{ columns.is_show.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <!-- <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.staff_id.show">
                <select-staff
                  v-model="filterForm.staff_id"
                  :class="mode ? 'input__day' : 'input__night'"
                  :id="filterForm.staff_id"
                >
                </select-staff>
              </th>
              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>

              <th v-if="columns.is_show.show">
                <crm-input
                  :placeholder="columns.is_show.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.is_show"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="complaint in list"
              :key="complaint.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ complaint.id }}</td>

              <td v-if="columns.staff_id.show">
                {{
                  complaint.staff
                    ? complaint.staff.name + " " + complaint.staff.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.comment.show">
                {{ complaint.comment }}
              </td>
              <td v-if="columns.is_show.show">
                <div class="image__preview application_img">
                  <el-image
                    class="my-c-avatar"
                    style="width: 50px; height: 50px"
                    :src="
                      complaint.image
                        ? baseUrl + complaint.image
                        : 'img/avatar.jpeg'
                    "
                    :preview-src-list="[
                      complaint.image
                        ? baseUrl + complaint.image
                        : 'img/avatar.jpeg'
                    ]"
                  >
                  </el-image>
                </div>
              </td>

              <td v-if="columns.created_at.show">
                {{ complaint.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ complaint.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="complaint"
                  :actions="actions"
                  @edit="edit"
                  @delete="destroy"
                  :permissionShow="'explanatory_letters.update'"
                  :permissionDestroy="'explanatory_letters.destroy'"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
    <div class="app-modal app-modal__full modal-color-bg">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        size="70%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create ref="drawerCreateChild" drawer="drawerCreate"></crm-create>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerUpdate"
        size="80%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      )
    };
  },
  mounted() {
    this.updateList();
  },
  computed: {
    ...mapGetters({
      list: "explanatoryLetter/list",
      columns: "explanatoryLetter/columns",
      pagination: "explanatoryLetter/pagination",
      statues: "explanatoryLetter/statues",
      filter: "explanatoryLetter/filter",
      sort: "explanatoryLetter/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "explanatoryLetter/explanatoryLetter",
      setPagination: "explanatoryLetter/setPagination",
      updateSort: "explanatoryLetter/updateSort",
      updateFilter: "explanatoryLetter/updateFilter",
      updateColumn: "explanatoryLetter/updateColumn",
      updatePagination: "explanatoryLetter/updatePagination",
      show: "explanatoryLetter/show",
      empty: "explanatoryLetter/empty",
      delete: "explanatoryLetter/destroy",
      refreshData: "explanatoryLetter/refreshData"
    })
  }
};
</script>
<style lang="scss">
.application_img {
  .my-c-avatar {
    height: 80px !important;
    width: 70px !important;
  }

  .my-c-avatar img {
    height: 80px !important;
    border-radius: 0;
  }
}
</style>
